'use client';

import { IndustryLink } from '@/components/Banners/IndustriesBanner/components/IndustryLink';
import defaultData from './data.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import './indes.scss';
import clsx from 'clsx';
import { SwiperNavButton } from '@/components/Swipers/SwiperNavButton';
import { SwiperNavArrowColor, SwiperNavArrowOrientation } from '@/images/svg/SwiperNavArrow';
import { Navigation, Autoplay } from 'swiper/modules';

interface IProps {
  wrapperClass?: string;
  contentClassname?: string;
  titleClassname?: string;
  linkTitleClassname?: string;
  list?: {
    title: string;
    href: string | null;
    iconSrc: string;
  }[];
}

export const IndustriesBanner = ({
  list,
  wrapperClass,
  contentClassname,
  titleClassname,
  linkTitleClassname,
}: IProps) => {
  const data = list || defaultData.industries;

  return (
    <div
      id="industries"
      className={clsx(`w-full flex justify-center items-center py-[32px] lg:px-[32px] scroll-mt-[96px]`, {
        [`${wrapperClass}`]: wrapperClass,
      })}
    >
      <div
        className={clsx(
          `
          w-full max-w-[1376px] h-[240px] relative 
          bg-black-1 lg:rounded-[24px] py-[64px] px-[80px]
          lg:px-[188px] flex flex-col gap-[40px] items-center justify-between
        `,
          {
            [`${contentClassname}`]: contentClassname,
          },
        )}
        data-testid="industries-swiper"
      >
        <h2
          className={clsx(
            `
            font-medium tracking-[2px] text-base leading-[24px] font-mono uppercase text-purple-9 text-center
            mobile:text-left whitespace-nowrap
          `,
            {
              [`${titleClassname}`]: titleClassname,
            },
          )}
        >
          Industries we work in
        </h2>

        <Swiper
          spaceBetween={12}
          grabCursor={true}
          className="industries-swiper"
          freeMode={true}
          autoplay={{
            delay: 3000,
          }}
          speed={700}
          loop={true}
          slidesPerView="auto"
          navigation={{ nextEl: '.right-industry', prevEl: '.left-industry' }}
          modules={[Navigation, Autoplay]}
        >
          {data.map(({ title, href, iconSrc }, index) => (
            <SwiperSlide
              key={index}
              className="industries-swiper-slide"
            >
              <IndustryLink
                title={title}
                href={href}
                iconSrc={iconSrc}
                classname="border-purple-13"
                titleClassname={linkTitleClassname}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperNavButton
          orientation={SwiperNavArrowOrientation.LEFT}
          color={SwiperNavArrowColor.PINK}
          className="
            bottom-[23.2%] left-[1%] border-purple-13 z-[1] left-industry
            mobile:bottom-[26%] mobile:left-[2%]
            lg:left-[9%]
            active:bg-white active:disabled:border-purple-13
            hover:disabled:border-purple-13
          "
        />
        <SwiperNavButton
          orientation={SwiperNavArrowOrientation.RIGHT}
          color={SwiperNavArrowColor.PINK}
          className="
            bottom-[23.2%] border-purple-13 z-[1] active:bg-white right-industry right-[1%]
            mobile:bottom-[26%] mobile:right-[2%]
            lg:right-[9%]
            hover:disabled:border-purple-13 active:disabled:border-purple-13"
        />
      </div>
    </div>
  );
};
