'use client';

import './styles.scss';

import clsx from 'clsx';
import { FaqItem } from './FaqItem';
import { ITabOption } from '@/interfaces/layout';
import { useCallback, useMemo, useState } from 'react';
import { OutlineTabs } from '../Insights/Tabs/outline';
import { AnimationBlock } from '@/components/AnimationBlock';
import { useRef } from 'react';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

interface IProps {
  title: string;
  titleClassname?: string;
  tabs: ITabOption[];
  videoUrl?: string;
  hideTabs?: boolean;
  description?: string;
  wrapperClassName?: string;
  innerClassName?: string;
  defaultValue: string;
}

const FaqAccordion = ({
  title,
  titleClassname,
  tabs,
  videoUrl,
  hideTabs = false,
  description,
  wrapperClassName,
  innerClassName,
  defaultValue,
}: IProps) => {
  const [activeTab, setActiveTab] = useState(defaultValue);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState<number | null>(null);

  const blockRef = useRef(null);

  const handleTabChange = useCallback((tab: ITabOption) => {
    setActiveTab(tab.value);
    setActiveDropdownIndex(null);
  }, []);

  const currentTabs = useMemo(() => tabs.find((tab) => tab.value === activeTab)?.items, [activeTab, tabs]);

  return (
    <section
      className={clsx('bg-black-11 lg:px-[80px] px-[20px] pt-[40px] pb-[80px] mobile:pb-[120px] flex justify-center', {
        [`${wrapperClassName}`]: wrapperClassName,
      })}
    >
      <AnimationBlock
        id="faq-anim"
        blockRef={blockRef}
      >
        <div
          className="relative overflow-hidden border-[0.5px] border-black-4 rounded-2xl max-w-[1136px]"
          ref={blockRef}
        >
          {videoUrl && (
            <ReactPlayer
              url={videoUrl}
              playing
              loop
              muted
              playsinline
              width="100%"
              height="100%"
              className="accordion-background-video absolute top-0 left-0 w-full h-full object-cover z-0"
            />
          )}
          <div
            className={clsx(
              `relative z-10 bg-black bg-opacity-70 py-[20px] px-[20px] 
                sm:py-[40px] sm:px-[40px] lg:py-[60px] lg:px-[70px]`,
              {
                [`${innerClassName}`]: innerClassName,
              },
            )}
          >
            <h4
              data-testid="section-title"
              className={clsx(
                `intro-title mb-xl tracking-[-0.96px] text-3xl leading-[42.2px] italic
            mobile:text-5xl mobile:leading-[67.2px] border-b-[0.5px] border-grey-7 pb-[15px]`,
                {
                  [`${titleClassname}`]: titleClassname,
                },
              )}
            >
              {title}
            </h4>

            <p className="text-white text-xl leading-[32px] mb-[20px]">{description}</p>
            {!hideTabs && (
              <OutlineTabs
                tabs={tabs}
                handleTabChange={handleTabChange}
                activeTab={`${activeTab}`}
                tabId={`${activeTab}`}
                wrapperClass="!mt-[30px] !mb-[40px]"
                tabClassName={clsx(
                  `rounded-full w-fit text-white hover:text-black-11 hover:bg-green-7 transition ease-out duration-300 
                 mobile:text-base text-xs mobile:!px-[12px] mobile:!py-[9px] !px-[7px] !py-[3px]
                `,
                  {
                    [`${titleClassname}`]: titleClassname,
                  },
                )}
                activeTabClassName="!font-normal !text-black-11 !bg-green-7"
                tabsListClassName="!gap-[4px] !justify-start"
              />
            )}
            <div>
              {currentTabs?.length &&
                currentTabs.map((item, index) => (
                  <FaqItem
                    key={item.title}
                    title={item.title}
                    isActive={index === activeDropdownIndex}
                    setActiveDropdownIndex={setActiveDropdownIndex}
                    index={index}
                  >
                    {item.content}
                  </FaqItem>
                ))}
            </div>
          </div>
        </div>
      </AnimationBlock>
    </section>
  );
};

export default FaqAccordion;
