import { Dispatch, ReactElement, SetStateAction } from 'react';
import clsx from 'clsx';

interface IProps {
  children: ReactElement | string;
  title: string;
  isActive: boolean;
  setActiveDropdownIndex: Dispatch<SetStateAction<number | null>>;
  index: number;
}

export const FaqItem = ({ title, children, isActive = false, setActiveDropdownIndex, index }: IProps) => {
  const onClick = () => {
    setActiveDropdownIndex(isActive ? null : index);
  };

  return (
    <div
      className={` 
        last:mb-0 cursor-pointer rounded-2xl
        border border-white first:mt-0 text-white
        p-xs mt-[20px]
      `}
      onClick={onClick}
      data-testid="accordion-item"
    >
      <div className="flex justify-between items-center gap-[10px]">
        <p className="leading-[19.2px] font-bold">{title}</p>
        <div className="border-[1px] rounded-full p-[9px]">
          <div
            className={clsx(
              `cursor-pointer relative w-[16px] h-[16px] before:content-[""] rotate-[270deg]
            before:block before:left-0 before:duration-500
            before:bg-white before:absolute before:top-[50%] before:w-full before:h-[3px] 
            after:content-[""] after:block after:left-0 after:duration-500
            after:bg-white after:absolute after:top-[50%] after:w-full after:h-[3px]
            before:translate-y-[-50%] after:translate-y-[-50%] after:rotate-[90deg]`,
              {
                [`before:opacity-0 before:translate-y-[-50%] before:rotate-[-90deg] 
                after:translate-y-[-50%] after:rotate-0`]: isActive,
              },
            )}
          />
        </div>
      </div>
      <div
        className={`
          grid w-full duration-[700ms] transition-[grid-template-rows] 
          ${isActive ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'}
        `}
      >
        <div className="overflow-hidden">
          <div
            className={`
              leading-[25.6px]
              pt-[8px] max-w-[93.1%] 
              
              [&>span]:block
              [&>span]:mb-[8px]
              [&>span>a]:underline
              [&>span>a]:text-semibold
              [&>span>a]:text-purple-7
              [&>span>a]:decoration-[1px]
              [&>span>a:hover]:opacity-80
              [&>span>a]:underline-offset-[2px]
                  
              [&>strong]:block
              [&>strong]:mb-[8px]
              [&>strong]:text-center
              [&>strong]:text-semibold
              
              [&>p]:mb-[8px]
              
              [&>b]:block
              [&>b]:mb-[8px]
              
              [&>ol>li]:mb-[8px]
              [&>ol]:list-outside
              [&>ol]:pl-xl
              [&>ol]:list-decimal
            `}
            dangerouslySetInnerHTML={{ __html: children }}
          />
        </div>
      </div>
    </div>
  );
};
